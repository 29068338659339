import { readonly, reactive } from 'vue'

const state = reactive({
  brand: {
    first: 'Maths',
    second: 'Line',
    third: 'Tutor',
    domain: '@gmail.com',
    caption: 'Maths tuition',
    email: 'MathsLineTutor@gmail.com'
  },
  courses: {
    heading: 'Study Maths from anywhere with immersive tuition',
    subheading: 'Our live online Maths tuition classes give you the freedom to sculpt your study around your existing lifestyle.',
    engg: [
      {
        name: 'Engineering Mathematics I',
        level: 'B.Tech / B.E. (VTU - Karnataka)',
        desc: 'modules',
        img: 'm1.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
        topics: [

        ]
      },
      {
        name: 'Engineering Mathematics II',
        level: 'B.Tech / B.E. (VTU - Karnataka)',
        desc: 'modules',
        img: 'm2.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
        topics: [

        ]
      },
      {
        name: 'Engineering Mathematics III',
        level: 'B.Tech / B.E. (VTU - Karnataka)',
        desc: 'modules',
        img: 'm3.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
        topics: [

        ]
      },
      {
        name: 'Engineering Mathematics IV',
        level: 'B.Tech / B.E. (VTU - Karnataka)',
        desc: 'modules',
        img: 'm4.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '2 classes per week',
        dropin: 'live online classes',
        techstack: ['algebra', 'calculus', 'complex analysis', 'linear programming'],
        boards: 'BSc. Engineering',
        examboards: ['BSc. Engineering degrees',],
        topics: [

        ]
      }
    ],
    boards: [
      {
        name: 'R D Sharma + NCERT Maths',
        level: 'Class 11',
        desc: 'modules',
        img: 'rds_11.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '5 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'CBSE, ICSE',
        examboards: ['CBSE', 'ICSE'],
        chapters: [
          {
            name: 'Sets'
          },
          {
            name: 'Relations'
          },
          {
            name: 'Functions'
          },
          {
            name: 'Measurement Of Angles'
          },
          {
            name: 'Trigonometric Functions'
          },
          {
            name: 'Graphs Of Trigonometric Functions'
          },
          {
            name: 'Trigonometric Ratios Of Compound Angles'
          },
          {
            name: 'Transformation Formulae'
          },
          {
            name: 'Trigonometric Ratios Of Multiple And Sub Multiple Angles'
          },
          {
            name: 'Sine And Cosine Formulae And Their Applications'
          },
          {
            name: 'Trigonometric Equations'
          },
          {
            name: 'Mathematical Induction'
          },
          {
            name: 'Complex Numbers'
          },
          {
            name: 'Quadratic Equations'
          },
          {
            name: 'Linear Inequations'
          },
          {
            name: 'Permutations'
          },
          {
            name: 'Combinations'
          },
          {
            name: 'Binomial Theorem'
          },
          {
            name: 'Arithmetic Progressions'
          },
          {
            name: 'Geometric Progressions'
          },
          {
            name: 'Some Special Series'
          },
          {
            name: 'Brief Review Of Cartesian System Of Rectangular Coordinates'
          },
          {
            name: 'The Straight Lines'
          },
          {
            name: 'The Circle'
          },
          {
            name: 'Parabola'
          },
          {
            name: 'Ellipse'
          },
          {
            name: 'Hyperbola'
          },
          {
            name: 'Introduction To 3D Coordinate Geometry'
          },
          {
            name: 'Limits'
          },
          {
            name: 'Derivatives'
          },
          {
            name: 'Mathematical Reasoning'
          },
          {
            name: 'Statistics'
          },
          {
            name: 'Probability'
          },
        ]
      },
      {
        name: 'R D Sharma + NCERT Maths',
        level: 'Class 12',
        desc: 'modules',
        img: 'rds_12.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '5 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'CBSE, ICSE',
        examboards: ['CBSE', 'ICSE'],
        chapters: [
          {
            name: 'Relations'
          },
          {
            name: 'Functions'
          },
          {
            name: 'Binary Operations'
          },
          {
            name: 'Inverse Trigonometric Functions'
          },
          {
            name: 'Algebra of Matrices'
          },
          {
            name: 'Determinants'
          },
          {
            name: 'Adjoint and Inverse of a Matrix'
          },
          {
            name: 'Solutions of Simultaneous Linear Equations'
          },
          {
            name: 'Continuity'
          },
          {
            name: 'Differentiability'
          },
          {
            name: 'Differentiation'
          },
          {
            name: 'Higher Order Derivatives'
          },
          {
            name: 'Derivative as a Rate Measurer'
          },
          {
            name: 'Differentials, Errors and Approximations'
          },
          {
            name: 'Mean Value Theorems'
          },
          {
            name: 'Tangents and Normals'
          },
          {
            name: 'Increasing and Decreasing Functions'
          },
          {
            name: 'Maxima and Minima'
          },
          {
            name: 'Indefinite Integrals'
          },
          {
            name: 'Definite Integrals'
          },
          {
            name: 'Area Bounded Regions'
          },
          {
            name: 'Differential Equations'
          },
          {
            name: 'Algebra of Vectors'
          },
          {
            name: 'Scalar or Dot Product'
          },
          {
            name: 'Vector or Cross Product'
          },
          {
            name: 'Scalar Triple Product'
          },
          {
            name: 'Direction Cosines and Direction Ratios'
          },
          {
            name: 'Straight Line in Space'
          },
          {
            name: 'The Plane'
          },
          {
            name: 'Linear Programming'
          },
          {
            name: 'Probability'
          },
          {
            name: 'Mean and Variance of a Random Variable'
          },
          {
            name: 'Binomial Distribution'
          },
        ]
      },
      {
        name: 'R S Aggarwal + NCERT Maths',
        level: 'Class 11',
        desc: 'modules',
        img: 'rsa_11.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '5 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'CBSE, ICSE',
        examboards: ['CBSE', 'ICSE'],
        chapters: [
          {
            name: 'Sets'
          },
          {
            name: 'Relations'
          },
          {
            name: 'Functions'
          },
          {
            name: 'Principle of Mathematical Induction'
          },
          {
            name: 'Complex Numbers and Quadratic Equations'
          },
          {
            name: 'Linear Inequations (In one variable)'
          },
          {
            name: 'Linear Inequations (In two variables)'
          },
          {
            name: 'Permutations'
          },
          {
            name: 'Combinations'
          },
          {
            name: 'Binomial Theorem'
          },
          {
            name: 'Arithmetic Progression'
          },
          {
            name: 'Geometrical Progression'
          },
          {
            name: 'Some Special Series'
          },
          {
            name: 'Measurement of Angles'
          },
          {
            name: 'Trigonometric, or Circular, Functions'
          },
          {
            name: 'Conditional Identities Involving the Angles of a Triangle'
          },
          {
            name: 'Trigonometric Equations'
          },
          {
            name: 'Solution of Triangles'
          },
          {
            name: 'Graphs of Trigonometric Functions'
          },
          {
            name: 'Straight Lines'
          },
          {
            name: 'Circle'
          },
          {
            name: 'Parabola'
          },
          {
            name: 'Ellipse'
          },
          {
            name: 'Hyperbola'
          },
          {
            name: 'Applications of Conic Sections'
          },
          {
            name: 'Three-Dimensional Geometry'
          },
          {
            name: 'Limits'
          },
          {
            name: 'Differentiation'
          },
          {
            name: 'Mathematical Reasoning'
          },
          {
            name: 'Statistics'
          },
          {
            name: 'Probability'
          },
        ]
      },
      {
        name: 'R S Aggarwal + NCERT Maths',
        level: 'Class 12',
        desc: 'modules',
        img: 'rsa_12.jpg',
        fee: '₹100 per month',
        unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
        payable: 'pay after your 2nd class every month',
        join: 'join anytime',
        contract: 'no contract',
        upfrontCost: 'no upfront costs',
        enrolment: 'no enrolment needed',
        sessions: 'drop-in drop-out group classes',
        dropinout: 'drop-in drop-out',
        repetitive: 'classes are repetitive so you miss none',
        advancepay: 'no advance payment',
        payOnly: 'pay if and only if you like the sessions',
        duration: '45-min per class',
        classes: '5 classes per week',
        dropin: 'live online classes',
        techstack: ['pure maths', 'statistics', 'mechanics'],
        boards: 'CBSE, ICSE',
        examboards: ['CBSE', 'ICSE'],
        chapters: [
          {
            name: 'Relation'
          },
          {
            name: 'Functions'
          },
          {
            name: 'Binary Operations'
          },
          {
            name: 'Inverse Trigonometric Functions'
          },
          {
            name: 'Matrices'
          },
          {
            name: 'Determinants'
          },
          {
            name: 'Adjoint and Inverse of a Matrix'
          },
          {
            name: 'System of Linear Equations'
          },
          {
            name: 'Continuity and Differentiability'
          },
          {
            name: 'Differentiation'
          },
          {
            name: 'Applications of Derivatives'
          },
          {
            name: 'Indefinite Integral'
          },
          {
            name: 'Method of Integration'
          },
          {
            name: 'Some Special Integrals'
          },
          {
            name: 'Integration Using Partial Fractions'
          },
          {
            name: 'Definite Integrals'
          },
          {
            name: 'Area of Bounded Regions'
          },
          {
            name: 'Differential Equations and Their Formation'
          },
          {
            name: 'Differential Equations with Variable Separable'
          },
          {
            name: 'Homogeneous Differential Equations'
          },
          {
            name: 'Linear Differential Equations'
          },
          {
            name: 'Vectors and Their Properties'
          },
          {
            name: 'Scalar, or Dot, Product of Vectors'
          },
          {
            name: 'Cross, or Vector, Product of Vectors'
          },
          {
            name: 'Product of Three Vectors'
          },
          {
            name: 'Fundamental Concepts of 3-Dimensional Geometry'
          },
          {
            name: 'Straight Line in Space'
          },
          {
            name: 'The Plane'
          },
          {
            name: 'Probability'
          },
          {
            name: 'Bayes Theorem and its Applications'
          },
          {
            name: 'Probability Distribution'
          },
          {
            name: 'Binomial Distribution'
          },
          {
            name: 'Linear Programming'
          },
        ]
      }
    ]
  },
  contact: {
    mobile: '074 2459 6325',
    email: 'MathslineTutor@gmail.com',
  },
  feeview: {
    paypal: 'https://www.paypal.me/MathsHourUK',
    fee: '₹100 per month',
    unlimited: 'unlimited classes',
        unmatchable: 'unmatchable tuition fee nationwide',
    payable: 'pay after your 2nd class every month',
    contract: 'no contract',
    advancepay: 'no advance payment',
    payOnly: 'pay if and only if you like the sessions',
  },

  timetable: [
    {
      name: 'Engineering Mathematics I',
      level: 'B.Tech / B.E. (VTU - Karnataka)',
      thumbnail: 'm1.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '10:30am',
      sat: '',
      sun: '',
    },
    {
      name: 'Engineering Mathematics II',
      level: 'B.Tech / B.E. (VTU - Karnataka)',
      thumbnail: 'm2.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '10:30am',
      sun: '10:30am',
    },
    {
      name: 'Engineering Mathematics III',
      level: 'B.Tech / B.E. (VTU - Karnataka)',
      thumbnail: 'm3.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '11:30am',
      sat: '',
      sun: '',
    },
    {
      name: 'Engineering Mathematics IV',
      level: 'B.Tech / B.E. (VTU - Karnataka)',
      thumbnail: 'm4.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '',
      fri: '',
      sat: '11:30am',
      sun: '11:30am',
    },
    {
      name: 'R D Sharma + NCERT Maths',
      level: 'Class 11',
      thumbnail: 'rds_11.jpg',
      mon: '10:30am',
      tue: '10:30am',
      wed: '10:30am',
      thu: '',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'R D Sharma + NCERT Maths',
      level: 'Class 12',
      thumbnail: 'rds_12.jpg',
      mon: '11:30am',
      tue: '11:30am',
      wed: '11:30am',
      thu: '',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'R S Aggarwal + NCERT Maths',
      level: 'Class 11',
      thumbnail: 'rsa_11.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '10:30am',
      fri: '',
      sat: '',
      sun: '',
    },
    {
      name: 'R S Aggarwal + NCERT Maths',
      level: 'Class 12',
      thumbnail: 'rsa_12.jpg',
      mon: '',
      tue: '',
      wed: '',
      thu: '11:30am',
      fri: '',
      sat: '',
      sun: '',
    }
  ],
})

const methods = {
  onLoad() {
    console.log('courses loaded')
  },
}

const getters = {

}

export default {
  state: readonly(state),
  methods,
  getters,
}