<script setup>
import { inject } from 'vue'

const store = inject('store');

</script>

<template>
  <h3 class="display-5 text-uppercase text-center text-grey mb-4">Timetable</h3>

  <div class="row">
    <div class="col-sm-12 bg-dark">
      <div class="table-responsive pt-3">
        <table class="table table-bordered table-dark table-striped table-sm text-center text-uppercase border-secondary align-middle">
          <thead>
            <tr>
              <th class="bg-black"><a href="https://www.timeanddate.com/worldclock/india/bengaluru" target="_blank" title="Indian Standard Time" class="text-decoration-none text-grey">Course time &middot; IST &#8986;</a></th>
              <th class="bg-black text-grey">Mon</th>
              <th class="bg-black text-grey">Tue</th>
              <th class="bg-black text-grey">Wed</th>
              <th class="bg-black text-grey">Thu</th>
              <th class="bg-black text-grey">Fri</th>
              <th class="bg-black text-grey">Sat</th>
              <th class="bg-black text-grey">Sun</th>
            </tr>
          </thead>
          <tbody>        
            <tr v-for="(daily, index) of store.state.timetable" :key="index">
              <th class="bg-black">
                <img class="" width="100" height="100" :src="require(`../assets/${daily.thumbnail}`)" :alt="daily.name"  /><br>
                <span class="badge bg-secondary text-black">{{ daily.name }}</span><br>
                <span class="badge bg-secondary text-black">{{ daily.level }}</span>
              </th>
              <td>
                <span class="badge bg-secondary">{{ daily.mon }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.tue }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.wed }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.thu }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.fri }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.sat }}</span>
              </td>
              <td>
                <span class="badge bg-secondary">{{ daily.sun }}</span>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="bg-black"><a href="https://www.timeanddate.com/worldclock/india/bengaluru" target="_blank" title="Indian Standard Time" class="text-decoration-none text-grey">Course time &middot; IST &#8986;</a></th>
              <th class="bg-black text-grey">Mon</th>
              <th class="bg-black text-grey">Tue</th>
              <th class="bg-black text-grey">Wed</th>
              <th class="bg-black text-grey">Thu</th>
              <th class="bg-black text-grey">Fri</th>
              <th class="bg-black text-grey">Sat</th>
              <th class="bg-black text-grey">Sun</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>