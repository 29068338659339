<script setup>
import { inject } from 'vue'
import CourseitemComponent from './CourseitemComponent.vue'

const store = inject('store')

</script>

<template>
  <courseitem-component :course-type="'Engineering Maths tuition'" :corresponding-courses="store.state.courses.engg"></courseitem-component>

  <courseitem-component :course-type="'Class 12 Boards Maths tuition'" :corresponding-courses="store.state.courses.boards"></courseitem-component>
</template>