<script setup>
import { inject } from 'vue'

const store = inject('store')

</script>

<template>
  <section>
    <div class="container">

      <h3 class="display-5 text-uppercase text-center mb-4">Tuition fee</h3>

      <div class="row">
        <div class="col-sm-12 col-md-4 offset-md-4">
          <div class="table-responsive">
            <table class="table table-dark table-striped table-sm text-center">
              <thead>
                <tr>
                  <th scope="col" class="h3 text-uppercase text-grey">Pay via Net Banking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">
                    <span class="h5 fw-bold text-light">Bank Account Details</span><br>
                    <span>Bank: ICICI Bank</span><br>
                    <span>Acc. Name: Santosh Singh</span><br>
                    <span>Acc. Num: 0298 0150 9600</span><br>
                    <span>IFSC: ICIC0000298</span>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">
                    <span class="h5 fw-bold text-light">{{ store.state.feeview.fee }}</span><br>
                    <span><small>{{ store.state.feeview.contract }}</small></span>
                  </td>
                </tr>
                <tr>
                  <td class="text-uppercase fw-bold text-grey">
                    <span class="h5 fw-bold text-light">{{ store.state.feeview.advancepay }}</span><br>
                    <span><small><small>{{ store.state.feeview.payOnly }}</small></small></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <pre>
        Leo is inviting you to a scheduled Zoom meeting.

        Topic: ConsoleLogLondon
        Time: This is a recurring meeting Meet anytime

        Join Zoom Meeting
        https://us02web.zoom.us/j/86887991572?pwd=STdHKysydVEweW1Tci83MHJpSnk3dz09

        Meeting ID: 868 8799 1572
        Passcode: 432234


      </pre> -->
      
    </div>
  </section>
</template>
